import request from '@/utils/request'
export function getDate(params) {
  return request({
    url: "apia/cgk/queryAll",
    params,
  });
}
export function getcxd(params) {
  return request({
    url: "apia/cgkCxd/selectByGuid",
    params,
  });
}
export function getwcr(params) {
  return request({
    url: "apia/cgkWcr/selectByname",
    params,
  });
}
export function getwcdw(params) {
  return request({
    url: "apia/cgkWcdw/selectByGuid",
    params,
  });
}
export function getzcxmqk(params) {
  return request({
    url: "apia/cgkZcxm/selectByname",
    params,
  });
}
export function getzz(params) {
  return request({
    url: "apia/cgkZllwbzzz/selectByGuid",
    params,
  });
}
export function getjjxy(params) {
  return request({
    url: "apia/cgkJjxy/selectByGuid",
    params,
  });
}
export function gethjqk(params) {
  return request({
    url: "apia/cgkHjqk/selectByGuid",
    params,
  });
}
export function getcy(params) {
  return request({
    url: "apia/cgk/selectZtc",
    params,
  });
}
export function getmbxxgjz(params) {
  return request({
    url: "apia/zjkKeyword/selectAll",
    params,
  });
}
export function getgjzbj(data) {
  return request({
    url: "apia/zjkKeyword/updateByCgguid",
    method: "post",
    data,
  });
}
export function removegjz(data) {
  return request({
    url: "apia/zjkKeyword/deleteByCgguid",
    method: "post",
    data,
  });
}
export function getAllmessage(data) {
  return request({
    url: "apia/zjkUrl/queryAll",
    data,
  });
}
export function updatemessage(data) {
  return request({
    url: "apia/zjkUrl/update",
    method: "post",
    data,
  });
}
export function deletemessage(data) {
  return request({
    url: "apia/zjkUrl/delete",
    method: "post",
    data,
  });
}
export function getallxx(data) {
  return request({
    url: "apia/zjk/selectAll",
    data,
  });
}

export function deletezj(data) {
  return request({
    url: "apia/zjk/deleteByCgguid",
    method: 'post',
    data,
  });
}
export function updatezjmessage(data) {
  return request({
    url: "apia/zjk/updateByzgguid",
    method: 'post',
    data,
  });
}
export function addzjmessage(data) {
  return request({
    url: "apia/zjk/add",
    method: 'post',
    data,
  });
}
export function getzc(data) {
  return request({
    url: "apia/zjk/selectZc",
    data,
  });
}
export function pipei(data) {
  return request({
    url: "apia/zjk/selectParams",
    method: 'post',
    data,
  });
}
export function getcggl(url, data) {
  return request({
    url: url,
    data,
  });
}
export function delatecg(data) {
  return request({
    url: "apia/cgkZcxm/delete",
    method: 'post',
    data,
  });
}
export function addzcxx(data) {
  return request({
    url: "apia/zhengceUrl/add",
    method: 'post',
    data,
  });
}
export function getzcxx(data) {
  return request({
    url: "apia/zhengceUrl/selectAll",
    data,
  });
}
export function delatezcxx(data) {
  return request({
    url: "apia/zhengceUrl/deleteByCgguid",
    method: 'post',
    data,
  });
}
export function reqwest(data) {
  return request({
    url: 'apia/cgk/upload?filetype=基本情况',
    method: 'post',
    data,
  });
}
//上传文件
export function getdcdfx(data) {
  console.log(data);
  return request({
    url: 'apia/cgkPjzb/queryAll',
    params: data,
  });
}
export function getzzt(data) {
  return request({
    url: 'apia/cgkPjzb/queryLevel',
    method: 'post',
    data,
  });
}
export function getgxt(url) {
  return request({
    url: url,
  });
}
export function getqsts() {
  return request({
    url: 'apia/cgk/selectField',
  });
}
export function getecharts(data) {
  return request({
    url: 'apia/cgk/selectEcharts',
    method: 'post',
    data
  });
}

export function getmbcg() {
  return request({
    url: 'apia/cgkZcxm/queryAllRealtion',
  });
}

export function getmecharts(data) {
  return request({
    url: 'apia/cgkZcxm/queryExtension',
    method: 'post',
    data
  });
}
export function getdtxx(url) {
  return request({
    url: url,
  });
}
export function getgather(data, params) {
  return request({
    url: 'apia/zhengceSs/searchZcUrl?search=' + params,
    data,
    method: 'post'
  });
}


export function addZjkKeyword(data) {
  return request({
    url: "apia/zjkKeyword/add",
    method: "post",
    data,
  });
}
export function addzjkUrl(data) {
  return request({
    url: "apia/zjkUrl/add",
    method: "post",
    data,
  });
}
export function zjkUpload(data) {
  return request({
    url: "apia/zjk/upload",
    method: "post",
    data,
  });
}

export function cgkSelectZtc(params) {
  return request({
    url: "apia/cgkZcxm/queryAllRealtion",
    params
  });
}

// 成果多维度呈现 一级领域
export function getField(params) {
  return request({
    url: "apia/cgkLycode/query",
    params
  });
}
export function getChildField(params) {
  return request({
    url: "apia/cgkLycode/queryLevel",
    params
  });
}
export function getFieldSearch(params) {
  return request({
    url: 'apia/cgk/selectZtc',
    params
  })
}
export function getFieldSchema(params) {
  return request({
    url: 'apia/cgk/selectLyRelation',
    params
  })
}
export function searchFinishPerson(params) {
  return request({
    url: 'apia/cgkWcr/selectSearch',
    params
  })
}